import { type SVGProps, forwardRef } from 'react'

export const Email = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      ref={ref}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M2.5 7.6c0-1.5 0-2.25.382-2.776a2 2 0 0 1 .442-.442C3.85 4 4.6 4 6.1 4h7.8c1.5 0 2.25 0 2.776.382.17.123.319.273.442.442.382.526.382 1.276.382 2.776v4.8c0 1.5 0 2.25-.382 2.776-.123.17-.273.319-.442.442C16.15 16 15.4 16 13.9 16H6.1c-1.5 0-2.25 0-2.776-.382a1.998 1.998 0 0 1-.442-.442C2.5 14.65 2.5 13.9 2.5 12.4V7.6Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M3.12 4.943 7.634 8.88c.997.87 1.496 1.304 2.08 1.389.19.027.382.027.572 0 .584-.085 1.082-.52 2.08-1.39l4.515-3.936"
      />
    </svg>
  )
)

Email.displayName = 'Email'
